<template>
  <!-- <div class="home"> -->
  <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <AddLocation />
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import AddLocation from "@/components/AddLocation.vue";

export default {
  name: "AddLocationView",
  components: {
    // HelloWorld,
    AddLocation,
  },
};
</script>
