<template>
  <b-container fluid>
    <b-row align-v="center" class="mt-3">
      <b-col sm="12" md="6">
        <h3>{{ item.clientcode }}</h3>
        <small v-if="item.pictures">{{ item.pictures.length }} images</small>
      </b-col>
      <b-col sm="12" md="6">
        <b-card>
          <b-row>
            <b-col sm="12" md="9">
              <b-form-file size="sm" v-on:change="uploadFile" accept=".jpg, .png" placeholder="" multiple no-drop></b-form-file>
              <!-- <input type="file" @change="uploadFile" multiple> -->
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col sm="6" md="2" class="mt-1">
              <b-button v-if="!processing" size="sm" v-on:click="uploadPictures" variant="success">Add</b-button>
              <b-icon v-if="processing" icon="arrow-counterclockwise" animation="spin-reverse-pulse" font-scale="3"></b-icon>
            </b-col>
            <!-- <b-col sm="6" md="2" class="mt-1">
              <b-button size="sm" v-on:click="refreshFiles" variant="info">
                reload
              </b-button>
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col>
        <b-button size="sm" class="ml-2" v-on:click="getClient" variant="outline-primary">
          All
        </b-button>
        <b-button
          class="m-1"
          v-for="(picLocation, i) in pictureLocationsAlbum"
          v-bind:key="i"
          size="sm"
          v-on:click="seeParticularLocation(picLocation.text)"
          variant="outline-dark"
        >
          {{ picLocation.text }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="my-1">
      <b-col class="my-1" sm="6" v-for="(img, i) in item.pictures" v-bind:key="i">
        <b-card no-body title="" v-bind:img-src="imgSrc(img)" img-alt="Image" img-top>
          <!-- <b-card-text> </b-card-text> -->
          <template #footer>
            <!-- <small class="text-muted">{{ getPictureLocation(img, item.pictureMeta) }}</small> -->
            {{ img.name }} <br />
            <b-form-select
              class="mt-1"
              v-bind:value="img.location"
              :options="pictureLocationOptions"
              v-on:change="updatePictureLocation($event, img, item.pictureMeta)"
            ></b-form-select>

            <b-form-datepicker
              v-bind:value="formatDate(img, item)"
              size="sm"
              class="mt-1"
              v-on:input="updatePictureDate($event, img)"
              locale="en-US"
              v-bind:hide-header="hideheader"
              :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
            ></b-form-datepicker>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "ViewAlbum",
  props: {
    msg: String,
  },
  data: function() {
    return {
      item: {},
      filteredItem: {},
      picturemeta: null,
      clientCode: "",
      pictureLocationOptions: [],
      pictureLocationsAlbum: [],
      selectedPictureLoc: [],
      moment,
      hideheader: true,
      files: null,
      processing: false,
    };
  },
  created: function() {
    this.getPictureLocationOptions();
    this.getClient();
  },
  mounted() {},

  methods: {
    uploadFile(event) {
      this.files = event.target.files;
    },

    uploadPictures() {
      this.processing = true;
      // console.log(this.files);
      const formData = new FormData();
      for (const i of Object.keys(this.files)) {
        formData.append("file", this.files[i]);
      }
      // console.log(formData);
      let options = {
        file: this.files,
      };
      console.log(options);
      axios
        .post(process.env.VUE_APP_SERVER + "/api/uploadmultiple/" + this.$route.params.id, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.processing = false;
          this.getClient();
          // console.log(response.data);
          // window.location.replace("/view/" + this.$route.params.id);
        });
    },

    createLocationLink(loc) {
      return "?loc=" + loc;
    },
    seeParticularLocation(loc) {
      console.log(loc);
      this.item = [];
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios.get(process.env.VUE_APP_SERVER + "/api/clients/" + this.$route.params.id + "/location/" + loc, options).then((response) => {
        this.item = response.data;

        // console.log(this.item);
      });
    },

    formatDate(img) {
      // console.log(img);
      let mydate = moment(img.date).format("YYYY-MM-DD");
      this.item.pictureMeta.forEach((elmeta, i) => {
        if (img.name === elmeta.name && elmeta.picturedate) {
          mydate = moment(elmeta.picturedate).format("YYYY-MM-DD");
        }
      });
      return mydate;
    },
    imgSrc(img) {
      let picloc = null;
      picloc = process.env.VUE_APP_SERVER + "/api" + img.url;

      return picloc;
    },
    pictureLocationDropdown(img) {
      // console.log(img);
      return img.picturelocation;
    },

    updatePictureDate(ev, img) {
      // console.log(ev);
      // console.log(img);
      let options = {
        date: ev,
        name: img.name,
      };
      // console.log(options);
      axios
        .put(process.env.VUE_APP_SERVER + "/api/picturemeta/" + this.$route.params.id, options, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // console.log(response.data);
        });
    },

    updatePictureLocation(loc, img, pictureLocations) {
      if (loc == "add location") {
        window.location.replace("/addlocation");
        return;
      }
      let options = {
        location: loc,
        name: img.name,
      };
      axios
        .put(process.env.VUE_APP_SERVER + "/api/picturemeta/" + this.$route.params.id, options, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log(response.data);
        });
    },

    getPictureLocation(img, pictureLocations) {
      let picloc = null;
      pictureLocations.forEach((el) => {
        if (el.name === img.name) {
          picloc = el.picturelocation;
        }
      });
      return picloc;
    },

    getPictureLocationOptions() {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios.get(process.env.VUE_APP_SERVER + "/api/locations", options).then((response) => {
        //  console.log(response.data)
        this.pictureLocationOptions.push({
          value: "add location",
          text: "add location",
        });
        response.data.locations.forEach((el) => {
          this.pictureLocationOptions.push({
            value: el,
            text: el,
          });
        });
      });
    },
    refreshFiles() {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios.get(process.env.VUE_APP_SERVER + "/api/updatefiles/" + this.$route.params.id, options).then((response) => {
        this.getClient();
      });
    },

    getPictureMeta() {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios.get(process.env.VUE_APP_SERVER + "/pl-metas?clientcode=" + this.$route.params.id, options).then((response) => {
        this.picturemeta = response.data;
      });
    },

    getClient() {
      let options = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios.get(process.env.VUE_APP_SERVER + "/api/clients/" + this.$route.params.id, options).then((response) => {
        this.item = response.data;

        this.pictureLocationsAlbum = [];
        response.data.pictures.forEach((el) => {
          if (el.location) {
            let exist = false;
            this.pictureLocationsAlbum.forEach((elex) => {
              if (elex.text == el.location) {
                exist = true;
              }
            });
            if (!exist) {
              this.pictureLocationsAlbum.push({
                value: el.location,
                text: el.location,
              });
            }
          }
        });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
