<template>
  <!-- <div class="home"> -->
  <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <ViewAlbum />
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import ViewAlbum from "@/components/ViewAlbum.vue";

export default {
  name: "ViewAlbumView",
  components: {
    // HelloWorld,
    ViewAlbum,
  },
};
</script>
