<template>
  <b-container fluid>
    <b-row class="mt-5">
      <b-col cols="9" class="mx-auto">
        <b-card>
          <b-row>
            <b-col>
              <h3>Login</h3>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-alert v-bind:variant="messageColor" v-bind:show="messageShow">{{ message }}</b-alert>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col cols="3">
              Username
            </b-col>
            <b-col><b-input v-model="username"></b-input></b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="3">
              Password
            </b-col>
            <b-col>
              <b-input v-model="password"></b-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-2">
              <b-button variant="success" v-on:click="login">Login</b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col><b-link to="/register">Register</b-link></b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  props: {
    msg: String,
  },
  data: function() {
    return {
      items: [],
      location: "",
      username: null,
      password: null,
      processing: false,
      pictureLocationOptions: [],
      message: null,
      messageColor: "success",
      messageShow: false,
    };
  },
  created: function() {
    // this.getAllItems();
  },

  methods: {
    login() {
      this.messageShow = false;
      this.processing = true;
      let options = {
        username: this.username,
        password: this.password,
      };
      axios.post(process.env.VUE_APP_SERVER + "/api/loginuser/", options).then((response) => {
        this.processing = false;
        console.log(response.data);
        if (response.data.error) {
          this.message = response.data.error;
          this.messageShow = true;
          this.messageColor = "danger";
        }

        if (response.data.success) {
          this.message = response.data.message;
          this.messageShow = true;
          this.messageColor = "success";
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("username", this.username);
          window.location.replace("/albums");
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
