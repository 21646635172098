<template>
  <div>
 

    <b-container>
         <b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000" fade indicators img-width="1920" img-height="1280" :interval="4000" controls>
      <b-carousel-slide caption="Photo Storage" img-src="./img/vlad-bagacian-d1eaoAabeXs-unsplash.jpg"></b-carousel-slide>
      <b-carousel-slide caption="Photo Security" img-src="./img/hillshire-farm-YGcleYb9wEQ-unsplash.jpg"></b-carousel-slide>
      <b-carousel-slide caption="Photo Sharing" img-src="./img/alina-grubnyak-IsxaFsXi2rs-unsplash.jpg"></b-carousel-slide>
    </b-carousel>

    
      <b-row align-v="center"  class="mt-1">
        <b-col sm="12" md="6">
          <b-img-lazy v-bind="mainProps" :src="getImageUrl(80)" alt="Image 1"></b-img-lazy>
        </b-col>
        <b-col class="">
          <b-card title="the safer way to store your photos" sub-title="">
            <b-card-text>Each image you take represents a split-second in time that can never be recaptured.</b-card-text>

            <b-button variant="primary" to="/login">Get Started</b-button>
            <!-- <a href="#" class="card-link">Card link</a>
            <b-link href="#" class="card-link">Another link</b-link> -->
          </b-card>
        </b-col>
      </b-row>

      <b-row align-v="center" class="mt-1">
        <b-col sm="12" md="6">
          <b-card title="Embed your photos anywhere" sub-title="">
            <b-card-text>That image has the potential to shape memories for generations to come.</b-card-text>

            <b-button variant="primary" to="/login">Get Started</b-button>
            <!-- <a href="#" class="card-link">Card link</a>
            <b-link href="#" class="card-link">Another link</b-link> -->
          </b-card>
        </b-col>
        <b-col>
          <b-img-lazy v-bind="mainProps" :src="getImageUrl(82)" alt="Image 1"></b-img-lazy>
        </b-col>
      </b-row>

      <b-row align-v="center"  class="mt-1 mb-5">
        <b-col sm="12"  md="6">
          <b-img-lazy v-bind="mainProps" src="./img/omar-lopez-0-uzdU3gUYw-unsplash.jpg" alt="Image 1"></b-img-lazy>
        </b-col>
        <b-col>
          <b-card title="Store your photos at their best quality" sub-title="">
            <b-card-text
              >We believe that choosing to store your memory in the cloud shouldn’t compromise the archival quality of your image.</b-card-text
            >

            <b-button variant="primary" type="dark" to="/login">Get Started</b-button>
            <!-- <a href="#" class="card-link">Card link</a>
            <b-link href="#" class="card-link">Another link</b-link> -->
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },

  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 400,
        class: "my-5",
      },
    };
  },
  methods: {
    getImageUrl(imageId) {
      const { width, height } = this.mainProps;
      return `https://picsum.photos/${width}/${height}/?image=${imageId}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
