<template>
  <!-- <div class="home"> -->
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <AlbumsList />
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import AlbumsList from '@/components/AlbumsList.vue'

export default {
  name: 'AlbumsListView',
  components: {
    // HelloWorld,
    AlbumsList,

  }
}
</script>
