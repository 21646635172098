<template>
  <b-container fluid>
    <b-row class="mt-5">
      <b-col cols="9" class="mx-auto">
        <b-card>
           <b-row>
            <b-col>
              <b-alert v-bind:variant="messageColor" v-bind:show="messageShow">{{ message }}</b-alert>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col><b-link to="/login">Login</b-link></b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "Logout",
  props: {
    msg: String,
  },
  data: function() {
    return {
      items: [],
      location: "",
      username: null,
      password: null,
      processing: false,
      pictureLocationOptions: [],
      message: "You are logged out",
      messageColor: "success",
      messageShow: true,
    };
  },
  created: function() {
      localStorage.clear()
      window.location.replace("/");
  },

  methods: {
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
