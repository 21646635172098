<template>
<b-container>

<b-row class="mt-5" v-if="items.length==0">
  <b-col>
    <p>No albums yet</p>
    <b-link to="/addalbum" >Add Album</b-link>
  </b-col>
</b-row>
 <div class="p-5">
    <b-list-group>
      <b-list-group-item v-for="(item, i) in items" v-bind:key="i" v-bind:href="`/view/${item.clientcode}`" class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            {{ item.clientcode }}
          </h5>
          <small>{{ item.pictures.length }}</small>
          <!-- <small>3 days ago</small> -->
        </div>

        <!-- <p class="mb-1">
          Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius
          blandit.
        </p> -->

        <!-- <small>Donec id elit non mi porta.</small> -->
      </b-list-group-item>
    </b-list-group>
  </div>
</b-container>
 
</template>

<script>
import axios from "axios";
export default {
  name: "AlbumsList",
  props: {
    msg: String,
  },
  data: function() {
    return {
      items: [],
    };
  },
  created: function() {
    this.getClients();
  },

  methods: {
    getClients() {
       let options = {
         headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          }
      };
      // console.log(options)
      axios.get(process.env.VUE_APP_SERVER + "/api/clients", options).then((response) => {
        this.items = response.data;
        console.log(this.items);
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
