<template>
  <!-- <div class="home"> -->
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <AddAlbum />
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import AddAlbum from '@/components/AddAlbum.vue'

export default {
  name: 'AddAlbumView',
  components: {
    // HelloWorld,
    AddAlbum,

  }
}
</script>
