<template>
  <b-container fluid>
    <b-row class="mt-5">
      <b-col>
        <h3>
          New Location
        </h3>
      </b-col>
    </b-row>
    <b-row class="my-1" align-h="center">
      <b-col cols="6">
        <b-form-input v-model="location"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button v-if="!processing" v-on:click="add" variant="success">Create Location</b-button>
        <b-icon v-if="processing" icon="arrow-counterclockwise" animation="spin-reverse-pulse" font-scale="3"></b-icon>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col v-if="!pictureLocationOptions">You don't have any registered locations</b-col>
      <b-col v-if="pictureLocationOptions">Registered</b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button class="m-1" v-for="(picLocation, i) in pictureLocationOptions" v-bind:key="i" size="sm" variant="outline-dark">
          {{ picLocation.text }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "axios";
export default {
  name: "ClientList",
  props: {
    msg: String,
  },
  data: function() {
    return {
      items: [],
      location: "",
      processing: false,
      pictureLocationOptions: [],
    };
  },
  created: function() {
    this.getAllItems();
  },

  methods: {
    add() {
      this.processing = true;
      // console.log(localStorage.getItem("token"));
      let options = {
        location: this.location,
      };
      axios
        .post(process.env.VUE_APP_SERVER + "/api/locations", options, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          // this.items = response.data;
          // console.log(response.data);
          this.processing = false;
          this.getAllItems();
          // window.location.replace("/");
        });
    },
    getAllItems() {
      this.pictureLocationOptions = [];
      let options = {
        // clientcode: this.clientCode,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios.get(process.env.VUE_APP_SERVER + "/api/locations", options).then((response) => {
        // console.log(response.data);
        response.data.locations.forEach((el) => {
          this.pictureLocationOptions.push({
            value: el,
            text: el,
          });
        });

        // this.pictureLocationOptions = response.data;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
